"use client"
import createZustandContext from "./createZustandContext";
import { useEffect, useState } from "react";
import getSurchargeHistory from "../dataAccess/getSurchargeHistory";
import surchargeUpdateProcedure from "../dataAccess/surchargeUpdateProcedure";
import getSurchargesByProvider from "../dataAccess/getSurchargesByProvider";

const SurchargeManagerStore = createZustandContext(
    ({ set, get }) => {

        const contextObject = {
            shippingProviderId: undefined,
            serviceTypeId: undefined,
            surchargeId: undefined,
            applicableZoneId: undefined,
            price: undefined,
            cost: undefined,
            discount: undefined,
            effectiveEndDateId: undefined,
            effectiveEndDateId: undefined,
            serviceTypes: [],
            surcharges: [],
            applicableZones: [],
            tableData: [],
            previousTableData: [],
            historyData: []
        };

        return {
            ...contextObject,
            setShippingProviderId: (value) => set({ shippingProviderId: value }),
            handleServiceTypeChange: (value) => set({ selectedServiceType: value }),
            setSurcharges: (value) => set({ surcharges: value }),
            setApplicableZones: (value) => set({ applicableZones: value }),
            setSurchargeId: (value) => set({ surchargeId: value }),
            handlePriceChange: (price) =>
                set((state) => {
                    const discount = state.cost && price
                        ? ((state.price - cost) / state.price) * 100
                        : null;
                    return { price, discount: discount !== null ? discount.toFixed(2) : null };
                }),

            handleCostChange: (cost) =>
                set((state) => {
                    const discount = state.price && cost
                        ? ((state.price - cost) / state.price) * 100 
                        : null;
                    return { cost, discount: discount !== null ? discount.toFixed(2) : null };
                }),
            setPreviousData: (data) => set({ previousData: data }),
            handleTableDataChange: (value) => set({ tableData: value }),
            handleHistoryDataChange: (value) => set({ historyData: value }),
            handleStartDateChange: (value) => set({ effectiveStartDateId: value }),
            handleEndDateChange: (value) => set({ effectiveEndDateId: value }),
            refreshSurchargesAndZoneData: async (shippingProviderId) => {
                try {
                    const data = await getSurchargesByProvider(shippingProviderId);
                    get().setSurcharges(data);
                    get().setApplicableZones(data.map(surcharge => surcharge.AppliedToZones))
                } catch (error) {
                    throw error
                }
            },
            handleSurchargeChange: async (surchargeName) => {
                get().setSurchargeId(surchargeName);
                get().handleTableDataChange([]);
                const surchargeToSend = { surcharge: surchargeName };
                const history = await getSurchargeHistory(surchargeToSend);
                console.log("history: ", history)
                get().handleHistoryDataChange(history);
                get().handleTableDataChange(history);
            },
            handleApplicableZoneChange: (value) => {
                if (value == "N/A") { value = ""; }
                set({ applicableZoneId: value })
                const filteredData = get().historyData.filter(row => row.AppliedToZones == value);
                get().handleTableDataChange(filteredData);
            },
            validateCostPrice: (submittedValues) => {
                const costDiff = submittedValues.cost - get().tableData[0].Cost;
                const priceDiff = submittedValues.price - get().tableData[0].Price;
                //let validity object = {}, then each method, validityObject.Add()
                if (submittedValues.Cost > submittedValues.Price) {
                    let validityObject = { isValid: false, msg: "Cost is higher than price. Please check values." };
                    return validityObject;
                }
                if (costDiff >= 10 || priceDiff >= 10) {
                    let validityObject = { isValid: false, msg: "Price or Cost increased by $10 or more from last update." };
                    return validityObject;
                }
                if (priceDiff < 0 || costDiff < 0) {
                    let validityObject = { isValid: false, msg: "Price or Cost decreased from last update." };
                    return validityObject;
                }
                if (get().tableData[0].Price < get().tableData[0].Cost) {
                    let validityObject = { isValid: false, msg: "Surcharge was previously discounted. Do we still get a discount?" };
                    return validityObject;
                }

                let validityObject = { isValid: true, msg: "" }
                return validityObject
            },
            validateDates: (submittedValues) => {
                const submittedStart = new Date(submittedValues.effectiveStartDate);
                const effectiveStartDate = `${submittedStart.getFullYear()}-${String(submittedStart.getMonth() + 1).padStart(2, '0')}-${String(submittedStart.getDate()).padStart(2, '0')}`;
                const comparableStartDate = Date.parse(effectiveStartDate);
                const historicalStartDate = get().tableData[0].EffectiveStartDateUtc
                if (comparableStartDate < historicalStartDate) {
                    let validityObject = { isValid: false, msg: "New start date cannot predate historical start date." }
                    return validityObject;
                }

                const submittedEnd = new Date(submittedValues.effectiveEndDate);
                const effectiveEndDate = `${submittedEnd.getFullYear()}-${String(submittedEnd.getMonth() + 1).padStart(2, '0')}-${String(submittedEnd.getDate()).padStart(2, '0')}`;
                const comparableEndDate = Date.parse(effectiveEndDate);
                const historicalEndDate = get().tableData[0].EffectiveEndDateUtc
                if (comparableEndDate < historicalEndDate) {
                    let validityObject = { isValid: false, msg: "New end date cannot predate historical start date." }
                    return validityObject;
                }

                if (comparableEndDate < comparableStartDate) {
                    let validityObject = { isValid: false, msg: "New end date cannot predate new start date" }
                    return validityObject;
                }
                let validityObject = { isValid: true, msg: "" }
                return validityObject;
            },
            handleShippingProviderChange: (selectedShippingProvider) => {
                get().setShippingProviderId(selectedShippingProvider);
                get().refreshSurchargesAndZoneData(selectedShippingProvider);
                set({ discount: undefined });
            },
        };
    },
    {
        name: "SurchargeManagerStore",
        missingSelectorBehavior: "warn",
        verbose: true
    }
)

export const useSurchargeManagerStore = SurchargeManagerStore.useStore;
export const SurchargeManagerProvider = SurchargeManagerStore.Provider
